<template>
  <layout-full-light>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-full-light>
</template>

<script>
import LayoutFullLight from '@core/layouts/layout-full-light/LayoutFullLight.vue'
import Navbar from './components/Navbar.vue'

export default {
  components: {
    LayoutFullLight,
    Navbar
  },
}
</script>
